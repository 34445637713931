import { InputAdornment } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import { assetDelete, assetsGet } from 'api/assets';
import { AssetInput } from 'api/types/Assets';
import { FileItem } from 'api/types/FileItem';
import clsx from 'clsx';
import { useDebounce } from 'common/hooks/useDebounce';
import { AnchorElType } from 'common/types/AnchorElType';
import { bytesToSize, getFileName } from 'common/utils/helpers';
import { ButtonPelican } from 'components/ButtonPelican';
import { DataGrid, DataGridSettings, useDataGrid, WithGridContext } from 'components/DataGrid';
import { AddOutline, ArrowSmallOutline, Search } from 'components/IconPelican/icons';
import { SelectItem, SelectPelican } from 'components/SelectPelican';
import { SelectVariant } from 'components/SelectPelican/types/SelectVariant';
import { Space } from 'components/Space';
import { TableActions } from 'components/TableDynamic/TableActions/component';
import { TextInput } from 'components/TextInput';
import { ConfirmContext } from 'containers/ConfirmContext';
import { useSnackbar } from 'notistack';
import AssetsCreate from 'pages/AssetsPage/AssetsCreate/AssetsCreate';
import * as QueryString from 'query-string';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Cell, Column } from 'react-table';
import { getWorkspaces } from 'store/workspaceSlice';
import { usePageStyles } from 'theme/page-styles';

import { AssetProjects } from './AssetProjects/AssetProjects';
import { getSizeColor } from './helpers';
import { Props } from './props';
import { useAssetStyles } from './styles';

const AssetsPage: React.FC<Props> = ({ inModal, searchName, handleSelectAsset, assetType }) => {
  const styles = usePageStyles();
  const assetStyles = useAssetStyles();
  const history = useHistory();
  const location = useLocation();
  const workspaces = useSelector(getWorkspaces);
  const { selectedRow, isNotSingleSelected, tableInstance } = useDataGrid();

  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const confirm = useContext(ConfirmContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isCreate, setCreate] = React.useState(false);
  const [assets, setAssets] = React.useState<readonly FileItem[]>([]);
  const queryString: { readonly page?: string } = QueryString.parse(location.search);
  const [assetsQuery, setAssetsQuery] = React.useState<AssetInput>({
    page: Number(queryString.page || '1'),
    size: 10,
    type: assetType,
    fileName: searchName,
  });
  const [totalCount, setTotalCount] = React.useState(0);
  const [anchorEl, setAnchorEl] = useState<AnchorElType>(null);
  const [searchText, setSearchText] = useState<string>();

  const debouncedSearchTerm = useDebounce(searchText || '', 500);

  const columns: ReadonlyArray<Column<FileItem>> = useMemo(
    () => [
      {
        Header: `${t('Extension')}`,
        // eslint-disable-next-line react/display-name
        Cell: ({ row: { original: asset } }: Cell<FileItem>) => (
          <p className={assetStyles.fileType}>
            {asset.type.charAt(0) + asset.type.substring(1).toLowerCase()}
          </p>
        ),
      },
      {
        Header: `${t('Preview')}`,
        // eslint-disable-next-line react/display-name
        Cell: ({ row: { original: asset } }: Cell<FileItem>) => (
          <div className={assetStyles.previewContainer}>
            {asset.type === 'IMAGE' ? (
              <img className={assetStyles.preview} src={asset.link} alt="" />
            ) : (
              <img src={`/assets/${asset.type.toLowerCase()}-asset-icon.svg`} alt="" />
            )}
          </div>
        ),
      },
      {
        Header: `${t('Name')}`,
        // eslint-disable-next-line react/display-name
        Cell: ({ row: { original: asset } }: Cell<FileItem>) => (
          <p title={getFileName(asset.file_name)} className={assetStyles.fileName}>
            {getFileName(asset.file_name)}
          </p>
        ),
      },
      {
        Header: `${t('Type')}`,
        // eslint-disable-next-line react/display-name
        Cell: ({ row: { original: asset } }: Cell<FileItem>) => (
          <span className={assetStyles.extension}>.{asset.extension}</span>
        ),
      },
      {
        Header: `${t('Size')}`,
        // eslint-disable-next-line react/display-name
        Cell: ({ row: { original: asset } }: Cell<FileItem>) => (
          <span className={clsx(assetStyles.fileSize, assetStyles[getSizeColor(asset.size)])}>
            {bytesToSize(asset.size)}
          </span>
        ),
      },
      {
        Header: `${t('Uploaded')}`,
        // eslint-disable-next-line react/display-name
        Cell: ({ row: { original: asset } }: Cell<FileItem>) => (
          <span className={assetStyles.nowrap}>{asset.uploaded}</span>
        ),
      },
      {
        Header: `${t('User id uploaded')}`,
        accessor: 'user_id_uploaded',
      },
      {
        width: 200,
        Header: `${t('Projects')}`,
        // eslint-disable-next-line react/display-name
        Cell: ({ row: { original: asset } }: Cell<FileItem>) => (
          <AssetProjects projects={asset.projects} />
        ),
      },
    ],
    [t, assetStyles]
  );

  const typeOptions: readonly SelectItem[] = [
    {
      label: t('Image'),
      value: 'IMAGE',
    },
    {
      label: t('Video'),
      value: 'VIDEO',
    },
    {
      label: t('Audio'),
      value: 'AUDIO',
    },
    {
      label: t('Document'),
      value: 'DOCUMENT',
    },
  ];

  const getAssets = React.useCallback(async () => {
    try {
      setLoading(true);
      const paramsQuery = Object.entries(assetsQuery)
        .filter(([, value]) => !!value)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
      const workspacesQuery = workspaces.map((w) => `projectIds=${w.workspaceId}`).join('&');
      const result = await assetsGet([paramsQuery, workspacesQuery].join('&'));
      setAssets(result.list);
      setTotalCount(result.totalCount);
    } catch (e) {
      enqueueSnackbar(
        `${t('Error on loading')} ${t('assets')} ${t(e.response?.data?.message || e)}`,
        {
          variant: 'error',
        }
      );
    } finally {
      setLoading(false);
    }
  }, [assetsQuery, enqueueSnackbar, t, workspaces]);

  const handlePageChange = React.useCallback(
    (page: number): void => {
      setAssetsQuery((prev) => ({ ...prev, page }));
      !inModal && history.push(`/asset?page=${page}`);
    },
    [history, inModal]
  );

  const handleRowsPerPageChange = React.useCallback(
    (size: number): void => {
      setAssetsQuery({ ...assetsQuery, page: 1, size });
      !inModal && history.push(`/asset?page=1`);
    },
    [assetsQuery, history, inModal]
  );

  const onDelete = (): void => {
    confirm({ description: t('Delete asset?') }).then(async () => {
      try {
        const result = await assetDelete(selectedRow?.id);
        if (result.statusCode === 0) {
          setAssetsQuery({ ...assetsQuery });
        }
        enqueueSnackbar(t(result.message), { variant: result.variant });
        tableInstance?.toggleAllRowsSelected(false);
      } catch (e) {
        enqueueSnackbar(`${t('Error deleting')} ${t(e.response?.data?.message || e)}`, {
          variant: 'error',
        });
      }
    });
  };

  const handleSizeClick = useCallback((event: React.MouseEvent<React.ReactNode>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleSizeClose = (): void => {
    setAnchorEl(null);
  };

  const setAssetType = React.useCallback(
    (type: AssetInput['type']) => {
      !inModal && history.push(`/asset?page=1`);
      setAssetsQuery({ ...assetsQuery, type: type, page: 1 });
    },
    [inModal, history, assetsQuery]
  );

  const handleOpen = (item: FileItem): void => {
    history.push(`/asset/${item.id}`);
  };

  useEffect(() => {
    getAssets();
  }, [getAssets]);

  useEffect(() => {
    if (assetsQuery.fileName !== searchName && searchName !== undefined) {
      setAssetsQuery((prev) => ({
        ...prev,
        fileName: searchName ?? assetsQuery.fileName,
      }));
    }
  }, [searchName, assetsQuery]);

  const onSelectItems = useCallback(() => {
    handleSelectAsset && handleSelectAsset(selectedRow as FileItem);
  }, [selectedRow, handleSelectAsset]);

  useEffect(() => {
    onSelectItems();
  }, [onSelectItems]);

  useEffect(() => {
    searchText !== undefined &&
      setAssetsQuery((prev) => ({ ...prev, fileName: debouncedSearchTerm, page: 1 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return (
    <div className={styles.relative}>
      <div className={styles.container}>
        {!inModal && (
          <div className={clsx(styles.flex, styles.marginBottom)}>
            <h1 className={styles.heading}>{t('Assets')}</h1>
            <div className={styles.createButton}>
              <TableActions>
                <ButtonPelican
                  variant="contained"
                  color="primary"
                  startIcon={<AddOutline />}
                  onClick={() => {
                    setCreate(true);
                  }}
                  permissions={['ASSET_CREATE', 'ASSET_BULK_CREATE']}
                >
                  {t('New Asset')}
                </ButtonPelican>
              </TableActions>
            </div>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 10,
          }}
        >
          {!inModal && (
            <div className={clsx(styles.flex)}>
              <TextInput
                onChange={(e) => {
                  const val = e.target.value;
                  !inModal && history.push(`/asset?page=1`);
                  setSearchText(val);
                }}
                value={searchText}
                placeholder={t('Search by name')}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          )}
          <Space size="middle" align="end">
            <div className={assetStyles.selectType}>
              <SelectPelican
                variant={SelectVariant.Text}
                id="assetType"
                isClearable
                placeholder={t('Type')}
                size="small"
                options={typeOptions}
                selected={(val) =>
                  setAssetType(val ? ((val as SelectItem).value as AssetInput['type']) : '')
                }
              />
            </div>
            <ButtonPelican
              color="default"
              onClick={handleSizeClick}
              size="small"
              endIcon={<ArrowSmallOutline />}
              classes={{ endIcon: assetStyles.endIcon, root: assetStyles.btn }}
              variant="text"
            >
              {assetsQuery.sizeFrom || assetsQuery.sizeTo
                ? (assetsQuery.sizeFrom ? assetsQuery.sizeFrom / 1048576 : '?') +
                  '-' +
                  (assetsQuery.sizeTo ? assetsQuery.sizeTo / 1048576 : '?') +
                  'Mb'
                : t('Size')}
            </ButtonPelican>
            <Popover
              open={Boolean(anchorEl)}
              onClose={handleSizeClose}
              anchorEl={anchorEl}
              classes={{ paper: assetStyles.paper }}
            >
              <div className={assetStyles.sizeBlock}>
                <TextInput
                  type="number"
                  onChange={(e) => {
                    const val = e.target.value;
                    setAssetsQuery({
                      ...assetsQuery,
                      sizeFrom: val ? Number(val) * 1048576 : undefined,
                      page: 1,
                    });
                  }}
                  value={assetsQuery.sizeFrom && assetsQuery.sizeFrom / 1048576}
                  inputClassName={assetStyles.filterInput}
                  placeholder={t('From')}
                />
                <span>-</span>
                <TextInput
                  type="number"
                  onChange={(e) => {
                    const val = e.target.value;
                    setAssetsQuery({
                      ...assetsQuery,
                      sizeTo: val ? Number(val) * 1048576 : undefined,
                      page: 1,
                    });
                  }}
                  value={assetsQuery.sizeTo && assetsQuery.sizeTo / 1048576}
                  inputClassName={assetStyles.filterInput}
                  placeholder={t('To')}
                />
                <span>Mb</span>
              </div>
            </Popover>
            <DataGridSettings />
          </Space>
        </div>
        <DataGrid
          columns={columns}
          data={assets}
          loading={loading}
          onOpen={inModal ? undefined : handleOpen}
          pagination={{
            rowsCount: totalCount,
            rowsPerPage: assetsQuery.size,
            handlePageChange: handlePageChange,
            handleRowsPerPageChange: handleRowsPerPageChange,
            initialPage: assetsQuery.page,
          }}
          noData={{
            img: 'asset-no-data',
            text: t('Add some Assets'),
            action: (
              <ButtonPelican
                variant="contained"
                color="primary"
                startIcon={<AddOutline />}
                onClick={() => {
                  setCreate(true);
                }}
              >
                <span>{t('New Asset')}</span>
              </ButtonPelican>
            ),
          }}
          footer={
            !inModal ? (
              <>
                <ButtonPelican
                  variant="text"
                  disabled={isNotSingleSelected}
                  onClick={() => history.push(`/asset/${selectedRow?.id}`)}
                >
                  {t('Edit')}
                </ButtonPelican>

                <ButtonPelican
                  variant="text"
                  disabled={isNotSingleSelected}
                  onClick={onDelete}
                  permissions="ASSET_DELETE"
                >
                  {t('Delete')}
                </ButtonPelican>
              </>
            ) : undefined
          }
        />
        <AssetsCreate
          open={isCreate}
          onCloseModal={(upload?: boolean) => {
            upload && getAssets();
            upload && tableInstance?.toggleAllRowsSelected(false);
            setCreate(false);
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        />
      </div>
    </div>
  );
};

export default WithGridContext(AssetsPage, 'AssetsPage');
