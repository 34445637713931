import { Menu, MenuItem } from '@material-ui/core';
import { AccessRightDto } from 'api/access-right';
import { backlinksOfModels } from 'api/back-links';
import {
  contentActionsGet,
  contentItemCommentPost,
  contentItemDelete,
  contentItemDuplicate,
  contentItemPatch,
  contentItemPost,
} from 'api/content';
import { contentTypeRightsGet } from 'api/content-types';
import { ModelReference } from 'api/types/BackLinks';
import { ContentAction } from 'api/types/ContentAction';
import { ContentItem, UpdateInfoForPatch } from 'api/types/ContentItem';
import { ContentTabType } from 'api/types/ContentTab';
import { ContentType } from 'api/types/ContentType';
import { IntegrationFieldResult } from 'api/types/IntegrationFieldResult';
import clsx from 'clsx';
import { AnchorElType } from 'common/types/AnchorElType';
import { ButtonPelican } from 'components/ButtonPelican';
import { DropdownButton } from 'components/DropdownButton';
import { CircleTripleOutline } from 'components/IconPelican/icons';
import BackLinks from 'containers/BackLinks/component';
import { ConfirmContext } from 'containers/ConfirmContext';
import { useSnackbar } from 'notistack';
import { moderate, sendMessage } from 'pages/Contents/actions';
import ContentDuplicate from 'pages/Contents/ContentDuplicate/component';
import { RequestResponse } from 'pages/Contents/types/Response';
import IntegrationFieldModal from 'pages/Integrations/IntegrationEdit/IntegrationFieldModal';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { DeepMap, FieldError } from 'react-hook-form';
import Hotkeys from 'react-hot-keys';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getTypes } from 'store/contentSlice';
import { getContentTabs, setNewContentTabs } from 'store/contentTabsSlice';
import { getWorkspaces } from 'store/workspaceSlice';
import { CustomTooltip } from 'templates/MainTemplate/Sidebar/NavMenu/NavMenuItem/NavMenuItemText';

import {
  getPatchDataArray,
  transformFromApi,
  transformToApi,
} from '../ContentEditorProvider/helpers';
import { useContentEditorProvider } from '../useContentEditorProvider';
import { Props } from './props';
import { useStyles } from './styles';

export const ContentActionButtons: React.FC<Props> = ({ fields }) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const {
    form,
    embedLevel,
    isReviewing,
    contentItemId,
    contentTypeSlug,
    toggledBar,
    isEmbeddedItem,
    onCloseButtonClick,
    onEmbeddedFieldSave,
    multilingual,
    selectedProjects,
    setRestRequires,
    setLengthValidates,
    selectedLocales,
    reloadContentItem,
    contentItem,
    setContentItemValue,
    setContentChanged,
    setContentLoading,
    isTreeView,
    // setTreeView,
    handleFieldChange,
    changedFields,
    params,
    parentSLug,
    parentId,
    fieldSlugInParent,
    updateInfo,
    setUpdateInfo,
    projectsChanged,
    treeUpdateInfo,
    setTreeUpdateInfo,
    handleValidate,
    selectedProjectOwner,
    rootProjectId,
  } = useContentEditorProvider();
  const { setValue, handleSubmit, getValues, reset, trigger } = form;

  const workspaces = useSelector(getWorkspaces);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const statusCode = contentItem.content_state?.code;
  const dispatch = useDispatch();
  const contentTabs = useSelector(getContentTabs);

  const [loadingActionCode, setLoadingActionCode] = useState<
    'save' | 'publish' | 'send' | 'menu' | 'reset' | ''
  >('');
  const saveLoading = useMemo(() => loadingActionCode === 'save', [loadingActionCode]);
  const publishLoading = useMemo(() => loadingActionCode === 'publish', [loadingActionCode]);

  const [openIntegration, setOpenIntegration] = useState(false);
  const [actions, setActions] = useState<ReadonlyArray<string>>([]);
  const types = useSelector(getTypes);
  const contentType = types.find((c: ContentType) => c.slug === contentTypeSlug);
  const workFlowId = types.find((val) => val.slug === contentTypeSlug)?.workflow_id;
  const isMacOs = !!window.navigator.platform.match(/^Mac/);
  const [openBacklinks, setOpenBacklinks] = useState(false);
  const [contentBacklinks, setContentBacklinks] = useState<ReadonlyArray<ModelReference>>([]);
  const [moderateBackLinksCode, setModerateBackLinksCode] = useState<string>();
  const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);
  const [archiveMessage, setArchiveMessage] = useState<string>();
  const [modelRights, setModelRights] = useState<ReadonlyArray<AccessRightDto>>([]);
  const [anchorEl, setAnchorEl] = React.useState<AnchorElType>(null);
  const confirm = useContext(ConfirmContext);

  const getContentActions = useCallback(
    async (id?: string | number) => {
      const actionsList: ReadonlyArray<ContentAction> = await contentActionsGet(
        contentTypeSlug,
        id || contentItemId
      );
      setActions(actionsList.map((a) => a.code));
      setContentChanged((prev: number) => prev + 1);
    },
    [contentTypeSlug, contentItemId, setContentChanged]
  );

  const handleOpenIntegration = useCallback(() => {
    setOpenIntegration(true);
  }, []);

  const handleCloseIntegration = useCallback(() => {
    setOpenIntegration(false);
  }, []);

  const handleAfterIntegrationClose = useCallback(
    (items?: ReadonlyArray<IntegrationFieldResult>) => {
      if (items && items[0]) {
        Object.keys(items[0])
          .filter((k) => k !== 'checked')
          .forEach((key) => {
            setValue(key, items[0][key]);
            handleFieldChange(key);
          });
      }
    },
    [handleFieldChange, setValue]
  );

  const hasIntegration = useMemo(() => {
    return contentType?.integration_id;
  }, [contentType]);

  const filterMultilingual = useCallback(
    (localeValues: ContentItem, code: string): ContentItem | undefined => {
      const newMulti: ContentItem = {};
      changedFields
        .filter((f) => f.includes('$'))
        .forEach((slug) => {
          const splitted = slug.split('$');
          if (code === splitted[0] && localeValues[splitted[1]] !== undefined) {
            newMulti[splitted[1]] = localeValues[splitted[1]];
          }
        });
      return Object.keys(newMulti).length > 0 ? newMulti : undefined;
    },
    [changedFields]
  );

  const handleReset = useCallback(async () => {
    setLoadingActionCode('reset');
    setContentLoading(true);
    try {
      if (contentItemId === '-1') {
        setContentItemValue({});
        form.reset();
      } else {
        await reloadContentItem();
      }
    } catch (e) {
      enqueueSnackbar(`${t('Error while fetching content')} ${e.response?.data?.message || e}`, {
        variant: 'error',
      });
    } finally {
      setTimeout(() => {
        setLoadingActionCode('');
        setContentLoading(false);
      }, 500);
      handleFieldChange(undefined);
    }
  }, [
    reloadContentItem,
    contentItemId,
    setContentItemValue,
    setContentLoading,
    form,
    handleFieldChange,
    enqueueSnackbar,
    t,
  ]);

  const changeTabStatus = useCallback(
    (status: ContentTabType['status'], id?: number) => {
      dispatch(
        setNewContentTabs(
          contentTabs.map((t) =>
            t.contentTypeSlug === contentTypeSlug && t.contentItemId === +contentItemId
              ? { ...t, status, contentItemId: id || t.contentItemId }
              : t
          )
        )
      );
    },
    [contentItemId, contentTabs, contentTypeSlug, dispatch]
  );

  const assignNewContent = useCallback(
    (contentData: ContentItem, getActions?: boolean) => {
      setContentItemValue(contentData);
      getActions && getContentActions(contentData.id);
      reset(transformFromApi(fields, contentData));
      setContentChanged((prev) => prev + 1);
      setUpdateInfo({});
      setTreeUpdateInfo([]);
      setLengthValidates([]);
      setRestRequires([]);
    },
    [
      fields,
      getContentActions,
      reset,
      setContentItemValue,
      setLengthValidates,
      setRestRequires,
      setUpdateInfo,
      setContentChanged,
      setTreeUpdateInfo,
    ]
  );

  const handlePublishClick = useCallback(
    async (result?: ContentItem) => {
      const id = result?.id;
      handleValidate();
      await trigger();
      setLoadingActionCode('publish');
      handleFieldChange(undefined);
      moderate(contentTypeSlug, [id || +contentItemId], 'approve')
        .then(async (res) => {
          if (res.data?.errors[0]?.description) {
            enqueueSnackbar(res.data.errors[0].description, {
              autoHideDuration: 10000,
              variant: 'error',
            });
            if (result) {
              assignNewContent(result);
            }
          } else {
            if ((res.status === 0 || res.status === 200) && res.data) {
              onCloseButtonClick &&
                embedLevel > -1 &&
                onCloseButtonClick(true, id ? String(id) : contentItemId, {
                  ...(result || contentItem),
                  id: id || +contentItemId,
                  content_state: res.data.results[0].content_state,
                });

              if (embedLevel === -1) {
                res.data &&
                  setContentItemValue({
                    ...(result || contentItem),
                    id: id || +contentItemId,
                    content_state: res.data.results[0].content_state,
                  });
                changeTabStatus('published');
                getContentActions(id);
              }
            } else if (result) {
              assignNewContent(result);
            }
            enqueueSnackbar(res.message, { variant: res.variant });
          }
        })
        .finally(() => {
          setLoadingActionCode('');
        });
    },
    [
      contentItem,
      setContentItemValue,
      enqueueSnackbar,
      getContentActions,
      contentItemId,
      contentTypeSlug,
      onCloseButtonClick,
      handleFieldChange,
      handleValidate,
      trigger,
      changeTabStatus,
      embedLevel,
      assignNewContent,
    ]
  );

  const submitResponse = useCallback(
    (
      result: RequestResponse & { readonly data: { readonly id: number } & ContentItem },
      doPublish?: boolean,
      createNew?: boolean
    ) => {
      if (result.statusCode === 0) {
        handleFieldChange(undefined);
        if (!isEmbeddedItem && embedLevel > -1) {
          if (doPublish) {
            handlePublishClick(result.data);
          } else {
            assignNewContent(result.data);
          }
        } else {
          if (contentItemId === '-1') {
            dispatch(
              setNewContentTabs([
                ...contentTabs,
                {
                  contentTypeSlug,
                  contentItemId: result.data.id,
                  status: result.data.content_state?.code || 'draft',
                  contentTypeLabel: types.find((t) => t.slug === contentTypeSlug)?.description,
                  contentSourceId: result.data.source_id || 0,
                },
              ])
            );
          } else if (createNew || !doPublish) {
            changeTabStatus(result.data.content_state?.code || 'draft', result.data.id);
          }

          if (createNew) {
            history.push(`/content/${contentTypeSlug}/-1`);
            setContentLoading(true);
            setContentItemValue({});
            setActions([]);
            reset();
            setTimeout(() => {
              setLoadingActionCode('');
              setContentLoading(false);
            }, 500);
            handleFieldChange(undefined);
          } else {
            doPublish === false && history.push(`/content/${contentTypeSlug}`);
            doPublish && handlePublishClick(result.data);
            if (doPublish === undefined) {
              assignNewContent(result.data, String(result.data) === contentItemId);
            }
          }
        }
      }
      enqueueSnackbar(t(result.message), { variant: result.variant });
    },
    [
      enqueueSnackbar,
      t,
      handleFieldChange,
      isEmbeddedItem,
      embedLevel,
      handlePublishClick,
      assignNewContent,
      contentItemId,
      dispatch,
      contentTabs,
      contentTypeSlug,
      types,
      changeTabStatus,
      setContentLoading,
      setContentItemValue,
      reset,
      history,
    ]
  );

  const onSave = useCallback(
    async (formData: ContentItem, doPublish?: boolean, createNew?: boolean): Promise<void> => {
      const transformed = transformToApi(
        contentItemId === '-1' ? fields : fields.filter((f) => changedFields.includes(f.slug)),
        formData,
        contentItemId === '-1'
      );

      const postData = {
        ...contentItem,
        ...transformed,
        content_state: {
          code: 'draft',
          description: 'Draft description',
          id: 1,
        },
        project_id: rootProjectId || (selectedProjectOwner.value as number),
        projects: selectedProjects,
        __uniqueKey: undefined,
        __isModalOpen: undefined,
      };

      if (multilingual && Object.keys(multilingual).length > 0) {
        postData['$.multilingual'] = {};
        selectedLocales.forEach((l) => {
          postData['$.multilingual'][l.code] = multilingual[l.code];
        });
      }

      if (
        embedLevel > -1 &&
        contentItemId === '-1' &&
        onEmbeddedFieldSave &&
        isEmbeddedItem &&
        fieldSlugInParent
      ) {
        if (parentId && parentId === '-1') {
          onEmbeddedFieldSave({
            ...contentItem,
            ...formData,
            '$.multilingual': Object.keys(multilingual).length > 0 ? multilingual : undefined,
          });
        } else if (parentId && parentId !== '-1') {
          onEmbeddedFieldSave(
            {
              ...contentItem,
              ...formData,
              '$.multilingual': Object.keys(multilingual).length > 0 ? multilingual : undefined,
            },
            {
              '$.updateInfo': {
                contentTypeSlug,
                rootDataId: +params.sourceId,
                rootContentTypeSlug: params.type,
                parentDataId: +parentId,
                parentContentTypeSlug: parentSLug,
                fieldSlugInParent,
                newData: postData,
                multilingualFieldSlugs: fields.filter((f) => f.multilingual).map((f) => f.slug),
              },
              '$.indexInField': updateInfo['$.indexInField'],
              '$.fields': fields,
            }
          );
        }
        onCloseButtonClick && onCloseButtonClick(true);
      }
      if (contentItemId === '-1' && (!isEmbeddedItem || embedLevel === -1)) {
        setLoadingActionCode('save');
        try {
          const result = await contentItemPost(
            !isEmbeddedItem ? contentTypeSlug : params.type,
            postData,
            embedLevel > -1 ? !doPublish : doPublish !== false
          );
          submitResponse(result, doPublish, createNew);
        } catch (e) {
          enqueueSnackbar('Error', { variant: 'error' });
        } finally {
          setLoadingActionCode('');
        }
      }

      const putData = {
        ...transformed,
        content_state: {
          code: 'draft',
          description: 'Draft description',
          id: 1,
        },
        project_id: contentItem.project_id || workspaces[0].workspaceId,
        projects: projectsChanged ? selectedProjects : undefined,
        __uniqueKey: undefined,
      };
      if (multilingual && Object.keys(multilingual).length > 0) {
        putData['$.multilingual'] = {};
        selectedLocales.forEach((l) => {
          putData['$.multilingual'][l.code] =
            multilingual[l.code] && filterMultilingual(multilingual[l.code], l.code);
        });
        if (
          putData['$.multilingual'] &&
          !Object.values(putData['$.multilingual']).some((v) => v !== undefined)
        ) {
          putData['$.multilingual'] = undefined;
        }
      }
      if (
        embedLevel > -1 &&
        contentItemId !== '-1' &&
        isEmbeddedItem &&
        parentId &&
        parentId !== '-1' &&
        onEmbeddedFieldSave
      ) {
        onEmbeddedFieldSave(
          {
            ...formData,
            '$.multilingual': Object.keys(multilingual).length > 0 ? multilingual : undefined,
            id: contentItem.id,
            content_state: contentItem.content_state,
          },
          {
            ...updateInfo,
            '$.updateInfo':
              changedFields.filter((s) => !s.includes('#')).length > 0 ||
              updateInfo?.['$.updateInfo']
                ? {
                    dataId: +contentItemId,
                    contentTypeSlug,
                    rootDataId: +params.sourceId,
                    rootContentTypeSlug: params.type,
                    newData: {
                      ...(updateInfo['$.updateInfo'] as UpdateInfoForPatch)?.newData,
                      ...putData,
                    },
                    parentContentTypeSlug: parentSLug,
                    parentDataId: +parentId,
                    multilingualFieldSlugs: Array.from(
                      new Set(
                        changedFields.filter((f) => f.includes('$')).map((f) => f.split('$')[1])
                      )
                    ),
                  }
                : undefined,
            '$.fields': fields,
            '$.saved': true,
          }
        );
        onCloseButtonClick && onCloseButtonClick(true);
      }
      if (contentItemId !== '-1' && (embedLevel === -1 || !isEmbeddedItem)) {
        setLoadingActionCode('save');
        const rootData: UpdateInfoForPatch = {
          dataId: +contentItemId,
          contentTypeSlug,
          rootDataId: +contentItemId,
          rootContentTypeSlug: contentTypeSlug,
          newData: putData,
          multilingualFieldSlugs: Array.from(
            new Set(changedFields.filter((f) => f.includes('$')).map((f) => f.split('$')[1]))
          ),
        };
        const patchData = isTreeView
          ? treeUpdateInfo.map((t) => ({ ...t.patchData, temporaryId: undefined }))
          : changedFields.filter((slug) => !slug.includes('#')).length > 0 || projectsChanged
          ? getPatchDataArray({ '$.updateInfo': rootData, ...updateInfo })
          : getPatchDataArray(updateInfo);
        try {
          const result = await contentItemPatch(
            patchData.map((u) => ({ ...u, __uniqueId: undefined })),
            !isEmbeddedItem ? contentTypeSlug : params.type,
            !isEmbeddedItem ? contentItemId : params.sourceId
          );
          submitResponse(result, doPublish, createNew);
        } catch (e) {
          console.error(e);
          enqueueSnackbar('Error', { variant: 'error' });
        } finally {
          setLoadingActionCode('');
        }
      }
    },
    [
      changedFields,
      contentItem,
      contentItemId,
      contentTypeSlug,
      embedLevel,
      enqueueSnackbar,
      fieldSlugInParent,
      fields,
      filterMultilingual,
      isEmbeddedItem,
      isTreeView,
      multilingual,
      onCloseButtonClick,
      onEmbeddedFieldSave,
      params,
      parentId,
      parentSLug,
      projectsChanged,
      rootProjectId,
      selectedLocales,
      selectedProjectOwner.value,
      selectedProjects,
      submitResponse,
      treeUpdateInfo,
      updateInfo,
      workspaces,
    ]
  );

  const handleSaveClick = (doPublish?: boolean, createNew?: boolean): void => {
    const { requireValid, minMaxValid } = handleValidate();

    const confirmSave = (
      formData: ContentItem,
      errors?: DeepMap<ContentItem, FieldError>
    ): void => {
      const errorTypes = Object.values(errors || {}).map((e) => e.type) as ReadonlyArray<string>;
      const haveRequiresType = errorTypes.includes('required');
      const haveMinMaxType = errorTypes.some((e) =>
        ['min', 'max', 'minLength', 'maxLength'].includes(e)
      );
      const warnings = [
        !requireValid || haveRequiresType ? `${t('There is required')};` : '',
        !minMaxValid || haveMinMaxType ? `${t('There is incorrect')};` : '',
      ].filter((s) => !!s);
      confirm({
        title: t('Saving'),
        warnings,
        description: t('Continue?'),
      }).then(() => {
        onSave(formData, doPublish, createNew);
      });
    };
    const onSubmit = handleSubmit(
      (formData) =>
        requireValid && minMaxValid
          ? onSave(formData, doPublish, createNew)
          : confirmSave(formData),
      (errors) => {
        confirmSave(getValues(), errors);
      }
    );

    selectedProjects && selectedProjects.length > 0
      ? onSubmit()
      : enqueueSnackbar(t('Select required projects'), { variant: 'error' });
  };
  const handleSendReviewWithMessage = useCallback(() => {
    confirm({
      title: t('Send for review'),
      description: t('Type message'),
      withMessage: true,
      rejectText: t('Cancel'),
      resolveText: t('Send'),
    }).then((message: string) => {
      setLoadingActionCode('send');
      // todo: fix promise inside promise
      sendMessage(contentTypeSlug, +contentItemId, message)
        .then(() => {
          moderate(contentTypeSlug, [Number(contentItemId)], 'publish')
            .then((res) => {
              if (res.statusCode === 0) {
                enqueueSnackbar(t(res.message), { variant: res.variant });
                res.data &&
                  setContentItemValue({
                    ...contentItem,
                    content_state: res.data.results[0].content_state,
                  });
                res.data && reloadContentItem(contentItemId);
                changeTabStatus('reviewing');
                getContentActions();
              } else {
                enqueueSnackbar(res.message, { variant: res.variant });
              }
            })
            .catch((e) => {
              enqueueSnackbar(`${t('Review error')} ${e.response?.data?.message || e}`, {
                variant: 'error',
              });
            });
        })
        .catch((e) => {
          enqueueSnackbar(`${t('Send message error')} ${e.response?.data?.message || e}`, {
            variant: 'error',
          });
        })
        .finally(() => {
          setLoadingActionCode('');
        });
    });
  }, [
    confirm,
    enqueueSnackbar,
    getContentActions,
    contentItemId,
    contentTypeSlug,
    t,
    contentItem,
    setContentItemValue,
    reloadContentItem,
    changeTabStatus,
  ]);

  const getModelRights = useCallback(async () => {
    try {
      const res = await contentTypeRightsGet(params.type);
      setModelRights(res);
    } catch (e) {
      enqueueSnackbar(`${t('Error loading model rights')} ${e.response?.data?.message || e}`, {
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, params.type, t]);

  const checkModelPermission = useCallback(
    (code: string) => modelRights.some((r) => r.code === code),
    [modelRights]
  );

  const handleSendReview = useCallback(async () => {
    setLoadingActionCode('send');

    try {
      const res: RequestResponse = await moderate(contentTypeSlug, [+contentItemId], 'publish');

      if (res.statusCode === 0) {
        res.data && reloadContentItem(contentItemId);
        changeTabStatus('reviewing');
      }
    } catch (e) {
      enqueueSnackbar(`${t('Review error')} ${t(e.response?.data?.message || e)}`, {
        variant: 'error',
      });
    } finally {
      setLoadingActionCode('');
    }
  }, [contentItemId, t, enqueueSnackbar, contentTypeSlug, reloadContentItem, changeTabStatus]);

  const handleReject = useCallback(() => {
    confirm({
      title: t('Return for revision'),
      description: t('Type message'),
      withMessage: true,
      rejectText: t('Cancel'),
      resolveText: t('Return'),
    }).then((message: string) => {
      setLoadingActionCode('send');
      sendMessage(contentTypeSlug, +contentItemId, message)
        .then((res) => {
          if (res.statusCode === 0) {
            moderate(contentTypeSlug, [+contentItemId], 'reject')
              .then((res) => {
                enqueueSnackbar(t(res.message), { variant: res.variant });
                res.data &&
                  setContentItemValue({
                    ...contentItem,
                    content_state: res.data.results[0].content_state,
                  });
                getContentActions();
                changeTabStatus('draft');
              })
              .catch((e) => {
                enqueueSnackbar(`${t('Reject error')} ${t(e.response?.data?.message || e)}`, {
                  variant: 'error',
                });
              });
          } else {
            enqueueSnackbar(res.message, { variant: res.variant });
          }
        })
        .catch((e) => {
          enqueueSnackbar(`${t('Send message error')} ${t(e.response?.data?.message || e)}`, {
            variant: 'error',
          });
        })
        .finally(() => {
          setLoadingActionCode('');
        });
    });
  }, [
    confirm,
    enqueueSnackbar,
    getContentActions,
    contentItemId,
    contentTypeSlug,
    t,
    contentItem,
    setContentItemValue,
    changeTabStatus,
  ]);

  const onArchive = useCallback(
    async (message?: string) => {
      setLoadingActionCode('menu');
      try {
        const res: RequestResponse = await moderate(contentTypeSlug, [+contentItemId], 'archive');
        if (message) {
          await contentItemCommentPost(
            { message: `Контент был архивирован с комментарием: ${message}` },
            contentTypeSlug,
            contentItemId
          );
        }
        if (res.statusCode === 0) {
          res.data &&
            setContentItemValue({
              ...contentItem,
              content_state: res.data.results[0].content_state,
            });
          getContentActions();
        }
        changeTabStatus('archived');
        setLoadingActionCode('');
        enqueueSnackbar(res.message, { variant: res.variant });
      } catch (e) {
        enqueueSnackbar(`${t('Archive error')} ${e.response?.data?.message || e}`, {
          variant: 'error',
        });
      }
    },
    [
      contentItem,
      contentItemId,
      contentTypeSlug,
      enqueueSnackbar,
      getContentActions,
      setContentItemValue,
      changeTabStatus,
      t,
    ]
  );

  const onDisable = useCallback(async () => {
    setLoadingActionCode('menu');
    try {
      const res = await contentItemDelete(contentTypeSlug, [+contentItemId]);
      history.push(`/content/${contentTypeSlug}`);
      enqueueSnackbar(t(res.message), { variant: res.variant });
      dispatch(
        setNewContentTabs(
          contentTabs.filter(
            (t) => t.contentTypeSlug !== contentTypeSlug || t.contentItemId !== +contentItemId
          )
        )
      );
    } catch (e) {
      enqueueSnackbar(`${t('Error deleting')} ${e.response?.data?.message || e}`, {
        variant: 'error',
      });
    } finally {
      setLoadingActionCode('');
    }
  }, [contentItemId, contentTabs, contentTypeSlug, dispatch, enqueueSnackbar, history, t]);

  const moderateHandle = useCallback(() => {
    if (moderateBackLinksCode === 'archive') {
      onArchive(archiveMessage);
      setArchiveMessage(undefined);
    }
    if (moderateBackLinksCode === 'disable') {
      onDisable();
    }
    setContentBacklinks([]);
  }, [moderateBackLinksCode, onArchive, onDisable, archiveMessage]);

  const handleArchive = useCallback(() => {
    confirm({
      title: t('Archive'),
      description: t('Archive comment') + ':',
      withMessage: true,
      rejectText: t('Cancel'),
      resolveText: t('Confirm'),
    })
      .then(async (message: string) => {
        setModerateBackLinksCode('archive');
        try {
          const refs = await backlinksOfModels(contentTypeSlug, +contentItemId);
          if (refs.length > 0) {
            setContentBacklinks(refs);
            setArchiveMessage(message);
          } else {
            onArchive(message);
          }
        } catch (e) {
          enqueueSnackbar(
            `${t('Error on loading')} ${t('backlinks')} ${t(e.response?.data?.message || e)}`,
            {
              variant: 'error',
            }
          );
        }
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoadingActionCode('');
      });
    handleMenuClose();
  }, [onArchive, t, confirm, contentTypeSlug, contentItemId, enqueueSnackbar]);

  const handleDisable = useCallback(() => {
    confirm({ rejectText: t('Cancel') })
      .then(async () => {
        setModerateBackLinksCode('disable');
        try {
          const refs = await backlinksOfModels(contentTypeSlug, +contentItemId);
          if (refs.length > 0) {
            setContentBacklinks(refs);
          } else {
            onDisable();
          }
        } catch (e) {
          onDisable();
          enqueueSnackbar(
            `${t('Error on loading')} ${t('backlinks')} ${t(e.response?.data?.message || e)}`,
            {
              variant: 'error',
            }
          );
        }
      })
      .finally(() => {
        setLoadingActionCode('');
      });
    handleMenuClose();
  }, [confirm, enqueueSnackbar, contentTypeSlug, t, onDisable, contentItemId]);

  const duplicateClose = useCallback(() => {
    setDuplicateModalOpen(false);
  }, []);

  const onDuplicate = useCallback(
    async (ownerId: number) => {
      setLoadingActionCode('menu');
      duplicateClose();
      try {
        const newContentItem = await contentItemDuplicate(contentTypeSlug, contentItemId, ownerId);
        history.replace(String(newContentItem.id));
        reloadContentItem();
      } catch (e) {
        enqueueSnackbar(`${t('Duplicate error')} ${e.response?.data?.message || e}`, {
          variant: 'error',
        });
      } finally {
        setLoadingActionCode('');
      }
    },
    [contentItemId, contentTypeSlug, enqueueSnackbar, history, reloadContentItem, duplicateClose, t]
  );

  const handleOpenBackLinks = useCallback(() => {
    setOpenBacklinks(true);
    handleMenuClose();
  }, []);

  const hasAction = useCallback((action: string): boolean => !!actions.find((a) => a === action), [
    actions,
  ]);

  const handleMenuClick = useCallback((event: React.MouseEvent<React.ReactNode>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleMenuClose = (): void => {
    setAnchorEl(null);
  };

  const onKeyDown = (hotkey: string, e: KeyboardEvent): void => {
    console.log('key downed');
    if (!isReviewing) {
      if (hotkey === 'command+shift+s' || hotkey === 'ctrl+shift+s') {
        handleSaveClick();
        e.preventDefault();
      }
      if (embedLevel === -1 || (!isEmbeddedItem && embedLevel > -1)) {
        if ((hotkey === 'command+option+l' || hotkey === 'ctrl+alt+l') && embedLevel === -1) {
          handleSaveClick(false);
          e.preventDefault();
        }
        if ((hotkey === 'command+shift+p' || hotkey === 'ctrl+shift+p') && hasAction('approve')) {
          handleSaveClick(true);
          e.preventDefault();
        }
        if ((hotkey === 'ctrl+alt+s' || hotkey === 'command+option+s') && embedLevel === -1) {
          handleSaveClick(false, true);
          e.preventDefault();
        }
      }
    }
  };

  useEffect(() => {
    if (contentItemId !== '-1') {
      getContentActions();
    }
  }, [getContentActions, contentItemId]);

  useEffect(() => {
    getModelRights();
  }, [getModelRights]);

  return (
    <Hotkeys
      allowRepeat
      keyName="ctrl+shift+s,command+shift+s,ctrl+alt+l,command+option+l,ctrl+shift+p,command+shift+p,command+option+s,ctrl+alt+s"
      onKeyDown={onKeyDown}
    >
      <div
        className={clsx(
          styles.contentActionContainer,
          !toggledBar && embedLevel === -1 && styles.smallWidth
        )}
        data-cy="buttons"
      >
        {/* {contentItemId && contentItemId !== '-1' && embedLevel === -1 && (
          <IconButton className={styles.tree} onClick={() => setTreeView(!isTreeView)}>
            <img width="20" src="/assets/tree.svg" alt="tree" />
          </IconButton>
        )}*/}
        <div className={styles.contentActionButtons}>
          {hasIntegration && (
            <ButtonPelican onClick={handleOpenIntegration}>
              {t('Load from integration')}
            </ButtonPelican>
          )}
          {embedLevel > -1 && isEmbeddedItem && (
            <ButtonPelican
              variant="contained"
              color="primary"
              onClick={() => handleSaveClick()}
              data-cy={`save-content-${embedLevel}`}
              disabled={isReviewing}
            >
              {t('Save')}
            </ButtonPelican>
          )}
          {embedLevel > -1 && (
            <ButtonPelican
              variant="contained"
              color="default"
              onClick={() => onCloseButtonClick && onCloseButtonClick(!contentItem.__isModalOpen)}
            >
              {t('Close')}
            </ButtonPelican>
          )}
          {(embedLevel === -1 || !isEmbeddedItem) && (
            <>
              <DropdownButton
                label={t('Send to')}
                variant="contained"
                color="primary"
                items={[
                  {
                    label: t('Send for review with message'),
                    onClick: handleSendReviewWithMessage,
                    disabled: !hasAction('publish') || !checkModelPermission('REVIEW'),
                    popoverText: !hasAction('publish')
                      ? 'Unavailable at current status'
                      : !checkModelPermission('REVIEW')
                      ? 'Insufficient permissions'
                      : '',
                  },
                  {
                    label: t('Send for review without message'),
                    onClick: handleSendReview,
                    disabled: !hasAction('publish') || !checkModelPermission('REVIEW'),
                    popoverText: !hasAction('publish')
                      ? 'Unavailable at current status'
                      : !checkModelPermission('REVIEW')
                      ? 'Insufficient permissions'
                      : '',
                  },
                  {
                    label: t('Return for revision'),
                    onClick: handleReject,
                    disabled: !hasAction('reject') || !checkModelPermission('REVIEW'),
                    popoverText: !hasAction('reject')
                      ? 'Unavailable at current status'
                      : !checkModelPermission('REJECT')
                      ? 'Insufficient permissions'
                      : '',
                  },
                ]}
              />
              <DropdownButton
                variant="contained"
                label={t('Save')}
                dataCy={`save-content-${embedLevel}`}
                color="primary"
                disabled={
                  isReviewing || !checkModelPermission(contentItemId === '-1' ? 'CREATE' : 'UPDATE')
                }
                onClick={() => handleSaveClick()}
                loading={saveLoading}
                items={[
                  {
                    id: 'save_publish',
                    label: t('Save and publish') + `(${isMacOs ? '&#8984' : 'Ctrl'}+Shift+P)`,
                    onClick: () => {
                      handleSaveClick(true);
                    },
                    disabled: workFlowId !== 3 || !checkModelPermission('PUBLISH'),
                    popoverText:
                      workFlowId !== 3
                        ? 'Unavailable in current workflow'
                        : !checkModelPermission('PUBLISH')
                        ? 'Insufficient permissions'
                        : '',
                  },
                  {
                    id: 'save_return',
                    label:
                      t('Save and return to list') +
                      ` (${isMacOs ? '&#8984' : 'Ctrl'}+${isMacOs ? 'Option' : 'Alt'}+L)`,
                    onClick: () => {
                      handleSaveClick(false);
                    },
                    disabled: embedLevel > -1 && !isEmbeddedItem,
                  },
                  {
                    id: 'save_create',
                    label:
                      t('Save and create new') +
                      ` (${isMacOs ? '&#8984' : 'Ctrl'}+${isMacOs ? 'Option' : 'Alt'}+S)`,
                    onClick: () => {
                      handleSaveClick(false, true);
                    },
                    disabled: embedLevel > -1 && !isEmbeddedItem,
                  },
                ]}
                popoverText={
                  !checkModelPermission(contentItemId === '-1' ? 'CREATE' : 'UPDATE')
                    ? 'Insufficient permissions'
                    : ''
                }
              />
              <ButtonPelican
                variant="contained"
                color="primary"
                loading={publishLoading}
                disabled={!hasAction('approve') || !checkModelPermission('PUBLISH')}
                onClick={() => handlePublishClick()}
                data-cy={`publish-content-${embedLevel}`}
                popoverText={
                  !hasAction('approve')
                    ? 'Unavailable at current status'
                    : !checkModelPermission('PUBLISH')
                    ? 'Insufficient permissions'
                    : ''
                }
              >
                {t('Publish')}
              </ButtonPelican>
              <ButtonPelican
                style={{ padding: 0, minWidth: 36 }}
                variant="text"
                color="default"
                onClick={handleMenuClick}
                loading={loadingActionCode === 'menu'}
              >
                <CircleTripleOutline />
              </ButtonPelican>
              <Menu
                id="content-editor-menu"
                anchorEl={anchorEl}
                keepMounted
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <CustomTooltip
                  disableHoverListener={hasAction('archive') && checkModelPermission('ARCHIVE')}
                  title={
                    t(
                      !hasAction('archive')
                        ? 'Unavailable at current status'
                        : 'Insufficient permissions'
                    ) || ''
                  }
                >
                  <div>
                    <MenuItem
                      disabled={!hasAction('archive') || !checkModelPermission('ARCHIVE')}
                      onClick={handleArchive}
                    >
                      {t('Archive')}
                    </MenuItem>
                  </div>
                </CustomTooltip>
                <CustomTooltip
                  disableHoverListener={statusCode === 'draft' && checkModelPermission('DELETE')}
                  title={
                    t(
                      statusCode !== 'draft'
                        ? 'Unavailable at current status'
                        : 'Insufficient permissions'
                    ) || ''
                  }
                >
                  <div>
                    <MenuItem
                      disabled={statusCode !== 'draft' || !checkModelPermission('DELETE')}
                      onClick={handleDisable}
                    >
                      {t('Delete')}
                    </MenuItem>
                  </div>
                </CustomTooltip>

                {embedLevel === -1 && (
                  <CustomTooltip
                    disableHoverListener={checkModelPermission('CREATE')}
                    title={t('Insufficient permissions') || ''}
                  >
                    <div>
                      <MenuItem
                        onClick={() => setDuplicateModalOpen(true)}
                        disabled={contentItemId === '-1' || !checkModelPermission('CREATE')}
                      >
                        {t('Duplicate')}
                      </MenuItem>
                    </div>
                  </CustomTooltip>
                )}
                <MenuItem
                  onClick={handleOpenBackLinks}
                  disabled={contentItemId === '-1' || embedLevel > 0}
                >
                  {t('Back links')}
                </MenuItem>
                {embedLevel === -1 && <MenuItem onClick={handleReset}>{t('Reset')}</MenuItem>}
              </Menu>
            </>
          )}
        </div>
        {openBacklinks && (
          <BackLinks
            open={openBacklinks}
            isModel={false}
            contentType={contentTypeSlug}
            onClose={() => setOpenBacklinks(false)}
            dataId={contentItemId}
          />
        )}
        {duplicateModalOpen && (
          <ContentDuplicate
            open={duplicateModalOpen}
            onClose={duplicateClose}
            ownerId={contentItem.project_id}
            onConfirm={onDuplicate}
          />
        )}
        {contentBacklinks.length > 0 && (
          <BackLinks
            open={contentBacklinks.length > 0}
            isModel={false}
            contentType={contentTypeSlug}
            onClose={() => setContentBacklinks([])}
            contentsReferences={[{ id: +contentItemId, backLinks: contentBacklinks }]}
            disableTitle={
              moderateBackLinksCode
                ? t(moderateBackLinksCode.charAt(0).toUpperCase() + moderateBackLinksCode.slice(1))
                : ''
            }
            disableDescription={`${t('Has backlinks')}. ${t(
              'Continue ' + (moderateBackLinksCode === 'archive' ? 'archiving?' : 'disabling?')
            )}`}
            onConfirmDisable={moderateHandle}
          />
        )}
        {openIntegration && (
          <IntegrationFieldModal
            contentTypeSlug={contentTypeSlug}
            open={openIntegration}
            close={handleCloseIntegration}
            onBeforeClose={handleAfterIntegrationClose}
          />
        )}
      </div>
    </Hotkeys>
  );
};

export default ContentActionButtons;
