import { Modal } from '@material-ui/core';
import { getRichtextContent } from 'api/content';
import { PlanetOutline } from 'components/IconPelican/icons';
import { TextInput } from 'components/TextInput';
import MultiLingualTextInput from 'containers/MultiLingualTextInput';
import { RichTextContent } from 'containers/RichTextContent';
import { useSnackbar } from 'notistack';
import { useStyles } from 'pages/ContentEditorPage/ContentEditor/ContentFormFields/ContentTextInput/styles';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Props } from './props';

export const TextTypeField: React.FC<Props> = ({
  field,
  contentItem,
  locales,
  value,
  multilingual,
  onChange,
  contentTypeSlug,
  changedFields,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const styles = useStyles();

  const isNumber = ['AMOUNT', 'INTEGER'].includes(field.type || '');
  const isWysiwyg = useMemo(() => field.wysiwyg || field.type === 'RICHTEXT', [field]);

  const [wysiwygOpen, setWysiwygOpen] = useState(false);
  const [localeWysiwyg, setLocaleWysiwyg] = useState<string>();
  const [loadingCurrentRichContent, setLoadingCurrentRichContent] = useState(false);
  const [richtextValue, setRichtextValue] = useState<string>();

  const handleGetRichtextContent = useCallback(
    async (locale?: string) => {
      setLoadingCurrentRichContent(true);
      try {
        const data = await getRichtextContent(
          contentTypeSlug,
          String(contentItem.id || -1),
          field.slug,
          locale
        );
        if (locale) {
          if (
            data['$.multilingual']?.[locale]?.[field.slug] &&
            typeof data['$.multilingual'][locale][field.slug] === 'string'
          ) {
            setRichtextValue(data['$.multilingual'][locale][field.slug]);
          } else {
            setRichtextValue('');
          }
        } else {
          if (typeof data[field.slug] === 'string') {
            setRichtextValue(data[field.slug]);
          } else {
            setRichtextValue('');
          }
        }
      } catch (e) {
        enqueueSnackbar(`${t('Error loading')} ${t(e.response?.data?.message || e)}`, {
          variant: 'error',
        });
      } finally {
        setLoadingCurrentRichContent(false);
      }
    },
    [contentItem.id, enqueueSnackbar, field.slug, contentTypeSlug, t]
  );

  const setMultiValue = useCallback(
    (locale: string, value?: string) => {
      onChange(
        {
          ...multilingual,
          [locale]: { ...(multilingual?.[locale] || {}), [field.slug]: value },
        },
        undefined,
        locale
      );
    },
    [field.slug, multilingual, onChange]
  );

  const handleSave = (content: string): void => {
    if (field.multilingual && localeWysiwyg) {
      setMultiValue(localeWysiwyg, content);
    } else {
      onChange(undefined, content);
    }
    handleClose();
  };

  useEffect(() => {
    if (wysiwygOpen) {
      if (
        changedFields &&
        changedFields.some((slug) =>
          field.multilingual ? slug === `${localeWysiwyg}$${field.slug}` : slug === field.slug
        )
      ) {
        setRichtextValue(
          field.multilingual ? multilingual?.[localeWysiwyg || ''][field.slug] : value
        );
      } else if (
        contentItem.id &&
        (field.multilingual
          ? multilingual?.[localeWysiwyg || ''][field.slug]
          : contentItem[field.slug])
      ) {
        handleGetRichtextContent(localeWysiwyg);
      } else {
        setRichtextValue('');
      }
    }
  }, [
    changedFields,
    contentItem,
    field,
    handleGetRichtextContent,
    localeWysiwyg,
    multilingual,
    value,
    wysiwygOpen,
  ]);

  const MultilingualInput = (): JSX.Element => (
    <>
      {locales.map((l) => (
        <div key={l.id} className={styles.multilangual}>
          <div className={styles.locale}>
            <PlanetOutline fontSize="small" htmlColor="#0052CC" />
            <span>{l.code}</span>
            {isWysiwyg && (
              <button
                onClick={() => {
                  setLocaleWysiwyg(l.code);
                  setWysiwygOpen(true);
                }}
                className={styles.editorButton}
                type="button"
              >
                {t('Open')}
              </button>
            )}
          </div>
          <MultiLingualTextInput
            multiline={!isWysiwyg}
            langTextValue={multilingual?.[l.code]?.[field.slug]}
            readOnly={field.disabled || isWysiwyg}
            handleMultiLangChange={(val: string) => {
              setMultiValue(l.code, val);
            }}
            isWysiwyg={isWysiwyg}
            id={`${field.slug}-${l.code}`}
            textMask={field.mask}
          />
        </div>
      ))}
    </>
  );

  const RegularInput = (): JSX.Element => (
    <div>
      {isWysiwyg && (
        <>
          <div className={styles.wysiwygWrapper}>
            <button
              onClick={() => {
                setWysiwygOpen(true);
              }}
              className={styles.editorButton}
              type="button"
            >
              {t('Open')}
            </button>
          </div>
          <p className={styles.wysiwyg} dangerouslySetInnerHTML={{ __html: value || '' }} />
        </>
      )}
      {!isWysiwyg && (
        <TextInput
          id={`${field.slug}`}
          value={value}
          type={isNumber ? 'number' : 'text'}
          multiline={!isWysiwyg && !isNumber}
          readOnly={isWysiwyg}
          disabled={field.disabled}
          textMask={isNumber ? undefined : field.mask}
          onKeyDown={
            field.type === 'INTEGER'
              ? (evt) => {
                  (evt.key === 'e' || evt.key === '.') && evt.preventDefault();
                }
              : undefined
          }
          onChange={(e) => onChange(undefined, e.target.value)}
        />
      )}
    </div>
  );

  const handleClose = useCallback(() => {
    setWysiwygOpen(false);
    setLocaleWysiwyg(undefined);
  }, []);

  return (
    <>
      {field.multilingual && MultilingualInput()}
      {!field.multilingual && RegularInput()}
      {wysiwygOpen && (
        <Modal
          open
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <RichTextContent
            id={`${field.slug}-${localeWysiwyg}`}
            selector={'textarea3'}
            onCancel={handleClose}
            onSave={handleSave}
            initialValue={richtextValue}
            loading={loadingCurrentRichContent}
          />
        </Modal>
      )}
    </>
  );
};
